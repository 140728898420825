import React from "react";
import "./Support.css";

const Support = () => {
  return (
    <div className="SupportContainer">
      <h2 className="SupportDescription">A community that will support, inspire, and empower you.</h2>
    </div>
  );
};

export default Support;

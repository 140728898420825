import React from "react";

const PrimaryButton = ({ onClick, text }) => {
  return (
    <button
      onClick={onClick}
      className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] min-w-[200px] px-5 hover:scale-120 transition-transform"
    >
      {text}
    </button>
  );
};

export default PrimaryButton;

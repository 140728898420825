import React from "react";
import { Table } from "@radix-ui/themes";
import moment from "moment";
import PrimaryButton from "../../button/PrimaryButton";
import { formatDate } from "../../../utility/formatDate";
import ViewTranscript from "../CustomModal/ViewTranscript";

const columns = ["Name", "Last Played", "", "", ""];

const data = [
  {
    name: "Luke Skywalker",
    date: new Date(),
  },
  {
    name: "Convincing John",
    date: new Date(),
  },
];

const CustomTable = ({ tableData }) => {
  return (
    <Table.Root>
      <Table.Header>
        <Table.Row>
          {columns.map((column, idx) => (
            <ColumnHeaderCell text={column} key={idx} />
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {tableData?.map((row, idx) => (
          <Table.Row className="border-none" key={idx}>
            <TableCell text={row?.title} />
            <TableCell text={formatDate(row?.createdAt)} />
            <Table.Cell>
              <PrimaryButton
                text="Start"
                className="h-10 w-12 text-base font-nohemi"
              />
            </Table.Cell>
            <Table.Cell>
              <PrimaryButton
                text="Edit"
                className="h-10 w-12 bg-primaryBlue text-base font-nohemi"
              />
            </Table.Cell>
            <Table.Cell>
              <ViewTranscript />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const ColumnHeaderCell = ({ text }) => (
  <Table.ColumnHeaderCell className="text-base font-nohemi text-[#4D4D4D]">
    {text}
  </Table.ColumnHeaderCell>
);

const TableCell = ({ text }) => (
  <Table.Cell>
    <div className="h-full flex items-center">{text}</div>
  </Table.Cell>
);

export default CustomTable;

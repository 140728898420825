import React from "react";
import clsxm from "../../utility/clsxm";

const PrimaryHeading = ({ heading, className }) => {
  return (
    <h2
      className={clsxm(
        "text-[43px] text-primaryPink font-audioWide mobile:text-[20px]",
        className
      )}
    >
      {heading}
    </h2>
  );
};

export default PrimaryHeading;

import "./PromiseYouEverything.css";

import React from "react";

const PromiseYouEverything = () => {
  return (
    <div className="PromiseYouEverythingContainer">
      <div className="PromiseYouEverythingContent">
        <div className="PromiseYouEverythingHeading">
          We can’t promise you everything
        </div>
        <div className="PromiseYouEverythingDescription">
          But we can promise you'll never be alone in your journey and you'll be
          part of something truly unique and innovative.{" "}
        </div>
      </div>
    </div>
  );
};

export default PromiseYouEverything;

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const fetchDataWithAxios = async (url, token) => {
  const URL = process.env.REACT_APP_BACKEND + url;
  try {
    const { data } = await axios.get(URL, {
      headers: {
        authtoken: token, // Include the token in the headers
      },
    });
    return data;
  } catch (error) {
    // Throw a custom error for React Query to handle
    let errorMessage = "An error occurred while fetching data.";
    if (error.response) {
      // Server responded with a status other than 200 range
      errorMessage = `Error: ${error.response.status} - ${
        error.response.data.message || error.response.statusText
      }`;
    } else if (error.request) {
      // No response from the server
      errorMessage = "No response received from the server.";
    } else {
      // Other errors
      errorMessage = error.message;
    }
    throw new Error(errorMessage);
  }
};

export const useQueryAuthInstance = (key, url, enabled = true, token) => {
  return useQuery({
    queryKey: [key],
    queryFn: () => fetchDataWithAxios(url, token), // Pass the token to the fetch function
    enabled,
    refetchOnWindowFocus: false,
  });
};

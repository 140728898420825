import React, { useEffect, useState } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { useParams } from "react-router-dom";
import useMutationInstance from "../../hooks/useMutationInstance";
import toast, { Toaster } from "react-hot-toast";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import Spinner from "../../components/Spinner/Spinner";
import roundLogo from "../../images/round-logo.svg";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import { useForm } from "react-hook-form";
import TextInput from "../../components/formInputs/TextInput";
import { encryptData } from "../../utility/encryption";
import { useQueryInstance } from "../../hooks/useQueryInstance";
import { useNavigate } from "react-router-dom";

const UPDATE_PASSWORD_URL = `${process.env.REACT_APP_BACKEND}/auth/updatePassword`;

const ResetPassword = () => {
  const { userId, requestId } = useParams();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm();
  const navigate = useNavigate();

  const { data, error, isFetching } = useQueryInstance(
    "verify-reset-link-data",
    `/auth/validateForgotPasswordData/${userId}/${requestId}`,
    !!userId && !!requestId
  );

  const resetForm = () => {
    reset({ password: "", confirm_password: "" });
  };

  // console.log("data", data);

  const { mutate: updatePasswordMutation, isPending: isPendingUpdatePassword } =
    useMutationInstance({
      onSuccess: () => {
        toast.success("Your password has been updated");
        setTimeout(() => {
          resetForm();
          navigate("/login");
        }, 2000);
      },
      onError: () => {
        resetForm();
      },
    });

  const onSubmit = (data) => {
    const { password, confirm_password } = data;

    if (password !== confirm_password) {
      toast.error("Password and Confirm password");
    }

    const encryptedPassword = encryptData(password);
    const encryptedData = encryptData(
      JSON.stringify({
        userId,
        requestId,
        password: encryptedPassword,
      })
    );
    const payload = {
      encryptedData,
    };
    updatePasswordMutation({ url: UPDATE_PASSWORD_URL, data: payload });
  };

  return (
    <NonProtectedLayout>
      {isFetching && (
        <div className="h-96 w-full flex flex-col justify-center items-center gap-4 text-center">
          <Spinner />
          Loading...
        </div>
      )}

      {!isFetching && !error && (
        <SpaceWrapper>
          <div className="px-[170px] mobile:px-[0px] py-16 text-white">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-9 mobile:gap-6 bg-primaryBlue relative rounded-[20px] px-[300px] mobile:px-10 pt-[153px] mobile:pt-16 pb-7"
            >
              <h2 className="text-center text-[32px] mobile:text-[24px] font-audioWide">
                RESET PASSWORD
              </h2>
              <div className="flex flex-col gap-6">
                <TextInput
                  type="password"
                  name="password"
                  placeholder="New Password"
                  register={register}
                  errors={errors}
                  isRequired={true}
                />
                <TextInput
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  register={register}
                  errors={errors}
                  isRequired={true}
                />
              </div>
              <div className="flex flex-col gap-6 mobile:gap-3 items-center justify-center">
                <button
                  type="submit"
                  disabled={isPendingUpdatePassword}
                  className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] px-5 hover:scale-110 transition-transform disabled:bg-opacity-70 disabled:cursor-not-allowed disabled:scale-100"
                >
                  {isPendingUpdatePassword ? <Spinner /> : "Reset Password"}
                </button>
              </div>
              <div className="h-[180px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
                <LazyImageObjectFit
                  src={roundLogo}
                  alt="Taara Quest Logo"
                  className="object-cover"
                  bg="none"
                />
              </div>
            </form>
          </div>
          <Toaster />
        </SpaceWrapper>
      )}
      {error && (
        <div className="h-96 w-full flex justify-center items-center text-center text-red-600">
          {error?.message.includes("400") ? "Invalid Link" : "Invalid Link"}
        </div>
      )}
    </NonProtectedLayout>
  );
};

export default ResetPassword;

import React, { useEffect, useState } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { Link, useParams } from "react-router-dom";
import useMutationInstance from "../../hooks/useMutationInstance";
import PrimaryHeading from "../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../components/button/PrimaryButton";

const URL = `${process.env.REACT_APP_BACKEND}/auth/verifyUser`;

const VerifyUser = () => {
  const { userId, verificationId } = useParams();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMesaage] = useState(null);

  const onError = (error) => {
    const errorResponse = error?.response?.data;
    // console.log(errorResponse);
    setErrorMessage(errorResponse?.message);
  };
  const onSuccess = (response) => {
    // console.log(response);
    setSuccessMesaage(response?.data?.message);
  };
  const { mutate, isPending } = useMutationInstance({
    onSuccess,
    onError,
  });

  useEffect(() => {
    if (!userId && !verificationId) {
      setErrorMessage("Invalid Verification Link");
    }
    mutate({ url: URL, data: { userId, verificationId } });
  }, []);

  return (
    <NonProtectedLayout>
      {isPending && (
        <div className="h-[400px] flex justify-center items-center">
          <p className="text-center">Loading...</p>
        </div>
      )}
      {successMessage && !isPending && (
        <div className="flex flex-col gap-[170px] pt-12 pb-24">
          <div className="flex flex-col items-center gap-10">
            <PrimaryHeading heading="SUCCESSFUL" />
            <PrimaryHeading heading="YOUR QUEST STARTS NOW" />
          </div>
          <div className="flex flex-col items-center gap-[96px]">
            <p className="w-[480px] text-center text-xl">
              Login and start your growth journey to become the person and
              leader you meant to be.
            </p>
            <Link to="/login">
              {" "}
              <PrimaryButton text="Go To Login" />
            </Link>
          </div>
        </div>
      )}
      {errorMessage && !isPending && (
        <div className="h-[580px] flex items-center flex-col pt-12 gap-[170px]">
          <PrimaryHeading heading="SORRY" />
          <div className="flex flex-col items-center gap-[96px]">
            <p className="w-[480px] text-center text-xl">
              We could not verify your account.
            </p>
            <Link to="/login">
              {" "}
              <PrimaryButton text="Go To Login" />
            </Link>
          </div>
        </div>
      )}
    </NonProtectedLayout>
  );
};

export default VerifyUser;

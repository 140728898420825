import { TextArea } from "@radix-ui/themes";
import React from "react";

const TextareaInput = ({ name, register, errors, placeholder, isRequired }) => {
  return (
    <div className="flex flex-col gap-1">
      <TextArea
        {...register(name, {
          required: isRequired
            ? { value: true, message: "This field is required" }
            : false,
        })}
        placeholder={placeholder || "Reply to comment…"}
        className="text-base rounded-lg px-6 py-2 h-[195px]"
        style={{ borderColor: errors[name] ? "red" : "gray" }}
      />
      {errors[name] && (
        <p className="text-sm text-red-500 mt-1">{errors[name].message}</p>
      )}
    </div>
  );
};

export default TextareaInput;

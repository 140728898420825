import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "./centerModalStyles.css";
import LazyImageObjectFit from "../../../LazyImage/LazyImageObjectFit";
import roundLogo from "../../../../images/round-logo.svg";
import PrimaryButton from "../../../button/PrimaryButton";

const AddCreditsModal = ({ closeModalAction, isOpen = false }) => (
  <Dialog.Root open={isOpen}>
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent-Credits">
        {/* Content goes here */}
        <div className="flex flex-col gap-12 bg-primaryBlue relative rounded-[20px] px-16 pt-[171px] pb-[100px]">
          <div className="w-[126px] h-[126px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
            <LazyImageObjectFit
              src={roundLogo}
              alt="Taara Quest Logo"
              className="object-cover"
              bg="none"
            />
          </div>
          <h2 className="text-[32px] font-audioWide text-white text-center">
            I WANT MORE
          </h2>
          <p className="font-nohemi font-semibold text-2xl text-[#E0E0E0] leading-[31px]">
            We love that you enjoy using Taara Quest. Get more credits to unlock
            more scenarios and fill up your conversation balance to super charge
            your growth.
          </p>
          <div className="flex justify-center">
            <div className="flex gap-[86px]">
              <PrimaryButton text="Add 20 Credits*" className="h-14" />
              <div className="Outline-button">Take me to pricing page</div>
            </div>
          </div>
          <p className="text-white leading-8">
            * Add 20 credits for $18 and get 10 more conversations.
          </p>
        </div>
        <Dialog.Close asChild onClick={closeModalAction}>
          <button className="IconButton cursor-pointer" aria-label="Close">
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default AddCreditsModal;

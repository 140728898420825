import React, { useState } from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import ProfileDetails from "../../../components/Profile/ProfileDetails";
import PrimaryButton from "../../../components/button/PrimaryButton";
import ProfileDetailsInput from "../../../components/Profile/ProfileDetailsInput";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Profile = () => {
  const [editDetails, setEditDetails] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const {
    // register,
    // handleSubmit,
    // watch,
    formState: { errors },
  } = useForm();
  const user = useSelector((state) => state.auth.user);
  // const onSubmit = (data) => console.log(data);

  const handleToggleEditDetails = () => setEditDetails((prev) => !prev);
  const handleToggleEditPassword = () => setEditPassword((prev) => !prev);

  return (
    <ProtectedRoutesLayout>
      <div className="px-[258px] pt-0 mobile:pt-8 mobile:px-4 flex flex-col gap-24 mobile:gap-12 pb-4 mobile:pb-12">
        <div className="flex flex-col gap-16 mobile:gap-6">
          {!editDetails ? (
            <div className="flex flex-col">
              <ProfileDetails label="Name" value={user?.name} />
              <ProfileDetails label="Email" value={user?.email} />
            </div>
          ) : (
            <form className="flex flex-col gap-3 mobile:gap-4">
              <ProfileDetailsInput label="Name" />
              <ProfileDetailsInput label="Email" />
            </form>
          )}

          <PrimaryButton
            text={editDetails ? "Save" : "Edit"}
            onClick={handleToggleEditDetails}
          />
        </div>

        <div className="flex flex-col gap-16 mobile:gap-6">
          {!editPassword ? (
            <div className="flex flex-col">
              <ProfileDetails label="Password" value="***********" />
            </div>
          ) : (
            <form className="flex flex-col gap-3">
              <ProfileDetailsInput label="Current Password" />
              <ProfileDetailsInput label="Repeat current Password" />
              <ProfileDetailsInput label="New Password" />
            </form>
          )}
          <PrimaryButton
            text="Change Password"
            onClick={handleToggleEditPassword}
          />
        </div>
      </div>
    </ProtectedRoutesLayout>
  );
};

export default Profile;

import PrimaryHeading from "../../primitives/PrimaryHeading";
import PricingPoints from "../PricingPoints";
import "./EmpowerHerAccess.css";

import React from "react";

const EmpowerHerAccess = () => {
  return (
    <div className="relative w-full">
      <div className="empower-her-access-container pt-[150px] mobile:pt-12 px-32 mobile:px-8 w-full relative">
        <div className="flex flex-row mobile:flex-col gap-0 mobile:gap-8">
          <div className="flex-1 mobile:flex mobile:justify-center">
            <div className="w-[330px] mobile:w-[60%] mobile:text-center">
              <PrimaryHeading heading="EMPOWER HER ACCESS" />
            </div>
          </div>
          <div className="flex-1 flex justify-end mobile:justify-center">
            <div className="w-[374px] mobile:w-[239px] flex flex-col gap-6 mr-12 mobile:mr-0">
              <Description description='Our "Empower Her Access" initiative makes your subscription doubly impactful.' />
              <Description
                description="For every Premium subscription, we gift the same to a deserving
              woman who is ready to take her growth to the next level but lacks
              the resources to do so."
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-8 mobile:px-4 absolute -bottom-24 mobile:-bottom-64">
        <PricingPoints />
      </div>
    </div>
  );
};

const Description = ({ description }) => {
  return (
    <p className="text-xl mobile:text-xs font-normal leading-9 text-justify mobile:text-center mobile:leading-6">
      {description}
    </p>
  );
};

export default EmpowerHerAccess;

import React from "react";
import "./TestimonialCard.css";
import LazyImage from "../LazyImage/LazyImage";

const TestimonialCard = ({ profile }) => {
  return (
    <div className="profileCard">
      <div className="leftProfileCard">
        <LazyImage src={profile.avatar} alt={profile.name} bg="none" />
        {/* <img src={profile.avatar} alt={profile.name} loading="lazy" /> */}
      </div>
      <div className="rightProfileCard">
        <div className="profileCardTitle mobile:text-center">
          {profile.name}
        </div>
        <div className="profileCardDescription mobile:text-center">
          {profile.text}
        </div>
        <div className="profileCardDesignation mobile:text-center mobile:font-bold">
          {profile.designation}
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;

import React from "react";

const ProfileDetails = ({ label, value }) => {
  return (
    <div className="flex flex-row gap-2 mobile:flex-col">
      <p className="w-[116px] font-bold">{label}</p>
      <p className="ml-16 mobile:ml-0 h-12">{value}</p>
    </div>
  );
};

export default ProfileDetails;

import React from "react";
import "./LandingHeroImage.css";
import UpdatedLazyImageObjectFit from "../../LazyImage/UpdatedLazyImageObjectFit";

const LandingHeroImage = ({ src, alt, heading, ctaText, onClick }) => {
  return (
    <div className="hero-container">
      <UpdatedLazyImageObjectFit
        src={src}
        alt={alt}
        bg="none"
        className="w-full h-[712px] mobile:h-[381px] bg-transparent rounded-[20px]"
      />
      <div className="overlayImage">
        <div className="overlayImageText">{heading}</div>
        <div className="overlayImageButton" onClick={onClick}>
          {ctaText}
        </div>
      </div>
    </div>
  );
};

export default LandingHeroImage;

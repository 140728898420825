import React from "react";
import clsxm from "../../utility/clsxm";
import Spinner from "../Spinner/Spinner";

const PrimaryButton = ({
  onClick,
  text,
  className,
  disable,
  isLoading = false,
}) => {
  return (
    <button
      onClick={() => {
        if (onClick) onClick();
      }}
      disabled={disable}
      className={clsxm(
        "w-fit object-contain text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] px-5 hover:scale-105 transition-transform",
        disable ? "bg-[#AEAEAE] cursor-not-allowed" : "bg-primaryPink",
        className
      )}
    >
      {isLoading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButton;

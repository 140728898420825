import React from "react";
import { useForm } from "react-hook-form";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import useMutationRateLimitingAPI from "../../hooks/useMutationRateLimitingAPI";
import toast, { Toaster } from "react-hot-toast";
import TextInput from "../../components/formInputs/TextInput";
import Spinner from "../../components/Spinner/Spinner";
import { Link } from "react-router-dom";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import roundLogo from "../../images/round-logo.svg";

const URL = `${process.env.REACT_APP_BACKEND}/auth/forgotPassword`;

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSuccess = (response) => {
    const {
      data: { message },
    } = response;
    toast.success(message);
    reset({ email: "" });
  };

  const onError = (error) => {
    // console.log("error", error);
    const errorResponse = error?.response?.data;
    if (errorResponse) {
      return toast.error(errorResponse?.message);
    }
    return toast.error("Something went wrong");
  };

  const { mutate, isPending } = useMutationRateLimitingAPI({
    limit: 5,
    timeWindow: 1000,
    onSuccess,
    onError,
  });

  const onSubmit = (data) => {
    const { email } = data;
    mutate({ url: URL, data: { email } });
  };

  return (
    <NonProtectedLayout>
      <SpaceWrapper>
        <div className="px-[170px] mobile:px-[0px] py-16 text-white">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-9 mobile:gap-6 bg-primaryBlue relative rounded-[20px] px-[300px] mobile:px-10 pt-[153px] mobile:pt-16 pb-7"
          >
            <h2 className="text-center text-[32px] mobile:text-[24px] font-audioWide">
              FORGOT PASSWORD?
            </h2>
            <div className="flex flex-col gap-6">
              <TextInput
                type="email"
                name="email"
                placeholder="Email Address"
                register={register}
                errors={errors}
                isRequired={true}
                validateInput
                pattern={/^[^\s@]+@[^\s@]+\.[^\s@]+$/}
              />
            </div>
            <div className="flex flex-col gap-6 mobile:gap-3 items-center justify-center">
              <button
                type="submit"
                disabled={isPending}
                className="border-none bg-primaryPink text-white text-lg text-center rounded-[10px] cursor-pointer h-[46px] px-5 hover:scale-110 transition-transform disabled:bg-opacity-70 disabled:cursor-not-allowed disabled:scale-100"
              >
                {isPending ? <Spinner /> : "Request reset link"}
              </button>
              {/* <p className="text-md text-sm text-center">
                Don’t have an account yet?{" "}
                <Link to="/signup" className="underline">
                  Sign up
                </Link>{" "}
                instead.
              </p> */}
            </div>
            <div className="w-[180px] h-[180px] mobile:w-[80px] mobile:h-[80px] absolute -top-8 left-1/2 -translate-x-1/2">
              <LazyImageObjectFit
                src={roundLogo}
                alt="Taara Quest Logo"
                className="object-cover"
                bg="none"
              />
            </div>
          </form>
        </div>
        <Toaster />
      </SpaceWrapper>
      <Toaster />
    </NonProtectedLayout>
  );
};

export default ForgotPassword;

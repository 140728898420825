import "./LandingHeroVideo.css";

import React from "react";

const LandingHeroVideo = ({ src, heading, ctaText, onClick }) => {
  return (
    <div className="videoOverlayContainer">
      <video
        src={src}
        className="waitlistArea"
        autoPlay
        loop
        muted
        preload="metadata"
      ></video>
      <div className="overlayVideo">
        <div className="overlayVideoText">{heading}</div>
        <div className="overlayVideoButton" onClick={onClick}>
          {ctaText}
        </div>
      </div>
    </div>
  );
};

export default LandingHeroVideo;

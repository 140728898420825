import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const fetchDataWithAxios = async (url) => {
  const URL = process.env.REACT_APP_BACKEND + url;
  try {
    const { data } = await axios.get(URL);
    return data;
  } catch (error) {
    // Throw a custom error for React Query to handle
    let errorMessage = 'An error occurred while fetching data.';
    if (error.response) {
      // Server responded with a status other than 200 range
      errorMessage = `Error: ${error.response.status} - ${error.response.data.message || error.response.statusText}`;
    } else if (error.request) {
      // No response from the server
      errorMessage = 'No response received from the server.';
    } else {
      // Other errors
      errorMessage = error.message;
    }
    throw new Error(errorMessage);
  }
};

export const useQueryInstance = (key, url, enabled = true) => {
  return useQuery({
    queryKey: [key],
    queryFn: () => fetchDataWithAxios(url),
    enabled,
    refetchOnWindowFocus: false,
  });
};

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "./styles.css";
import CreateScenarioForm from "./CreateScenarioForm";

const CustomModal = ({ appearances, closeModalAction, isOpen = false }) => (
  <Dialog.Root open={isOpen}>
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent pt-[96px] pb-[48px] px-[42px] flex flex-col gap-12">
        {/* Content goes here */}
        <div className="flex flex-col gap-7">
          <h3 className="text-[40px] font-audioWide text-center">Scenario</h3>
          <p className="text-base text-[#424242] px-20">
            A scenario is situation you would like to roleplay and get feedback
            on. Things to consider, improve or alter.
          </p>
        </div>
        <CreateScenarioForm appearances={appearances} closeModalAction={closeModalAction} />
        <Dialog.Close asChild onClick={closeModalAction}>
          <button className="IconButton cursor-pointer" aria-label="Close">
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default CustomModal;

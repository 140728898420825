import React, { useMemo } from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import PrimaryHeading from "../../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../../components/button/PrimaryButton";
import EachBillingDetails from "../../../components/Auth/Billing/EachBillingDetails";
import moment from "moment";

const Billing = () => {
  const generateDateFormat = useMemo(() => {
    const date = moment(new Date());
    return date.format("DD MMMM YYYY");
  }, []);
  return (
    <ProtectedRoutesLayout>
      <div className="flex flex-col gap-[106px] px-24 pb-12">
        <PrimaryHeading
          heading="Thanks for giving us the change to Help you grow"
          className="text-center"
        />
        <div className="flex flex-col gap-10 pl-16">
          <PrimaryHeading
            heading="Subscription Details"
            className="text-black"
          />
          <div className="flex flex-col gap-[35px]">
            <EachBillingDetails label="Current Plan" value="Grow Potential" className="w-[180px]" />
            <EachBillingDetails label="Subscription Status" value="Active" className="w-[180px]" />
            <EachBillingDetails label="Renews On" value={generateDateFormat} className="w-[180px]" />
            <PrimaryButton text="Manage Billing" className="bg-primaryBlue" />
          </div>
          <div className="flex flex-col gap-[35px]">
            <EachBillingDetails
              label="Coaching conversations remaining"
              value="2"
              className="w-[280px]"
            />
            <EachBillingDetails label="Scenarios remaining" value="3/5" className="w-[280px]" />

            <div className="flex gap-[111px]">
              <PrimaryButton text="Change Plan" className="bg-primaryBlue" />
              <PrimaryButton text="Get More Credits" />
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center">
          <PrimaryButton text="Go To Billing" className="bg-primaryBlue" />
        </div> */}
      </div>
    </ProtectedRoutesLayout>
  );
};

export default Billing;

import React from "react";
import "./HowToJoin.css";
import roundLogo from "../../../images/round-logo.svg";

const HowToJoin = () => {
  return (
    <div className="HowToJoinContainer">
      <div className="TopIconContainer">
        <img src={roundLogo} alt="round-logo" />
      </div>
      <div className="HowToJoinContent">
        <div className="HowToJoinHeading">HOW TO JOIN</div>
        <div className="HowToJoinDescription">
          You can become a Taara Ambassador by applying directly or through a
          recommendation from an existing Ambassador.
        </div>
        <button className="HowToJoinButton">Apply Now</button>
      </div>
    </div>
  );
};

export default HowToJoin;

import { useMutation } from "@tanstack/react-query";
import axios from "axios";

const useMutationInstance = ({ onSuccess, onError }) => {
  const executeMutation = async ({ url, data, token }) => {
    try {
      const headers = token ? { authtoken: token } : {};

      const response = await axios.post(url, data, { headers });
      if (onSuccess) {
        onSuccess(response);
      }
      return response;
    } catch (err) {
      if (onError) {
        onError(err);
      }
      throw err;
    }
  };

  // React Query's useMutation
  const mutation = useMutation({
    mutationFn: executeMutation,
  });

  return mutation;
};

export default useMutationInstance;

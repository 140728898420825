import { useEffect, useRef } from "react";

const LazyVideo = ({ className, poster, src }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Load the poster when the video is in the viewport
            videoElement.poster = poster;

            // Optionally preload the video src when it's in the viewport
            videoElement.src = src;
            observer.unobserve(videoElement); // Stop observing once loaded
          }
        });
      },
      {
        threshold: 0.25, // Load when 25% of the video is visible
      }
    );

    if (videoElement) {
      observer.observe(videoElement);
    }

    return () => {
      if (videoElement) observer.unobserve(videoElement);
    };
  }, []);

  return (
    <video className={className} ref={videoRef} controls preload="none"></video>
  );
};

export default LazyVideo;

import { useMutation } from "@tanstack/react-query";
import { useState, useRef } from "react";
import axios from "axios";

const useMutationRateLimitingAPI = ({
  limit = 3,
  timeWindow = 1000,
  onSuccess,
  onError,
}) => {
  const [error, setError] = useState(null);
  const timeStamps = useRef([]);

  const executeMutation = async ({ url, data }) => {
    const currentTime = Date.now();

    // Remove timestamps older than the time window
    timeStamps.current = timeStamps.current.filter(
      (timestamp) => currentTime - timestamp < timeWindow
    );

    if (timeStamps.current.length < limit) {
      // Allow the mutation to proceed if the rate limit is not exceeded
      timeStamps.current.push(currentTime);
      try {
        const response = await axios.post(url, data);
        if (onSuccess) {
          onSuccess(response);
        }
        return response;
      } catch (err) {
        setError(err);
        if (onError) {
          onError(err);
        }
        throw err;
      }
    } else {
      // If rate limit exceeded, throw an error or handle rate-limit exceeded case
      const errorMessage = "Rate limit exceeded. Please try again later.";
      const rateLimitError = new Error(errorMessage);
      onError({
        response: {
          data: { message: errorMessage },
        },
      });
      throw rateLimitError;
    }
  };

  // React Query's useMutation
  const mutation = useMutation({
    mutationFn: executeMutation,
  });

  return mutation;
};

export default useMutationRateLimitingAPI;

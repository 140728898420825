import React, { useState } from "react";
import TranscriptModal from "./TranscriptModal";

const ViewTranscript = () => {
  const [openTranscriptModal, setOpenTranscriptModal] = useState(false);
  const handleOpeTransactionModal = () => setOpenTranscriptModal(true);
  const handleCloseTranscriptModal = () => setOpenTranscriptModal(false);
  return (
    <>
      <div
        className="h-full flex items-center text-base font-nohemi text-[#9D9D9D] cursor-pointer"
        onClick={handleOpeTransactionModal}
      >
        view transcripts
      </div>
      <TranscriptModal
        isOpen={openTranscriptModal}
        closeModalAction={handleCloseTranscriptModal}
      />
    </>
  );
};

export default ViewTranscript;

import React, { useEffect } from "react";
import PrimaryHeading from "../../components/primitives/PrimaryHeading";
import PrimaryButton from "../../components/button/PrimaryButton";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import Markdown from "../../components/Markdown/Markdown";
import { Link } from "react-router-dom";

const markdownContent = `

![Image 1](https://taara-quest.s3.eu-central-1.amazonaws.com/images/pitch-training/Pitch+Practice+Landinpage+Image.webp)

This is your safe space to prepare for any type of pitch. Here, you can experiment with your style, structure, and voice, building a presence that feels authentic and strong.

Pitching goes beyond words—it's about how you show up. Self-perception shapes your presence, and high-stakes moments can stir self-doubt. This space gives you the chance to recognize and transform those thoughts, empowering you to pitch with confidence. Most importantly, it's designed to build self-awareness and reveal any mental barriers holding you back.

#### Scenario: 

You'll meet Jin Manson, a seasoned venture capitalist and partner at Vertex Ventures, known for its sharp focus on early-stage investments. He's heard many pitches today, and you're his last meeting. Make it count!

#### How it Works:
- **1. Roleplay**: Enter the practice room, open your mic, turn on your speakers, and deliver your 5-minute pitch. Investor Jin will listen and be ready for some Q&A after you're done. 
- **2. Reflection**: After your pitch and Q&A, Taara—your AI startup coach—will guide you through a 10-minute debrief. This is where real growth happens: identifying mental barriers and refining your approach.

#### Prototype Notice: 

Please note this is a prototype experience, so occasional glitches and bugs might arise.

Ready to practice your pitch? Let's get started! 🏆
    `;

const PitchTraining = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <NonProtectedLayout>
      <div className="flex flex-col items-center px-[254px] mobile:px-0 mt-12">
        <div className="flex flex-col gap-[21px] items-center px-12 mobile:px-4 mb-24 w-[867px] mobile:w-full">
          <PrimaryHeading
            heading="WELCOME TO TAARA PITCH TRAINING"
            className="text-center w-[80%]"
          />
          <Markdown content={markdownContent} />
          <div className="w-[477px] mobile:w-[80%] m-auto flex justify-center">
            <Link
              to="http://taara-quest-app.s3-website.eu-central-1.amazonaws.com/"
              target="_blank"
            >
              <PrimaryButton text="Start Pitch Training" className="w-full" />
            </Link>
          </div>
        </div>
      </div>
    </NonProtectedLayout>
  );
};

export default PitchTraining;

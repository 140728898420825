import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/authSlice";
import useMutationInstance from "../../hooks/useMutationInstance";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";

const URL = `${process.env.REACT_APP_BACKEND}/auth/logout`;

const SignOutButton = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.token);

  const onError = (error) => {
    const errorResponse = error?.response?.data;
    // console.log(errorResponse);
    toast.error(errorResponse?.message || "Something went wrong");
  };

  const onSuccess = (response) => {
    dispatch(logout());
    navigate("/login");
  };
  const { mutate, isPending } = useMutationInstance({
    onSuccess,
    onError,
  });

  const handleSignOut = () => {
    mutate({ url: URL, data: { token } });
  };

  return (
    <>
      <button
        type="button"
        className="px-4 py-2 w-[100px] bg-primaryBlue text-white text-center rounded-lg cursor-pointer"
        onClick={handleSignOut}
      >
        {isPending ? <Spinner /> : "Sign Out"}
      </button>
      <Toaster />
    </>
  );
};

export default SignOutButton;

import React, { useEffect } from "react";
import Markdown from "../../components/Markdown/Markdown";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";

const markdownContent = `
# TERMS OF SERVICE

<p1>Effective Date : October 29, 2024</p1>

**1. Agreement to Terms**

This Terms of Service Agreement (the “Agreement” or “Terms”) constitutes a legally binding agreement between Taara Quest, a limited liability entity headquartered in Leipzig, Germany, (“Taara Quest,” “we,” or “us”) and any individual or entity accessing or using our virtual roleplay and leadership development platform (the “Service”). By accessing or using the Service, you (“User” or “you”) acknowledge acceptance of these Terms in full. If you do not agree, do not access or use the Service.

**2. Services Provided**

Taara Quest provides Users with virtual roleplaying modules and leadership development resources (collectively, “Services”), allowing Users to create, interact, and engage within customized and pre-built scenarios for personal development purposes. These Services are intended solely for adult individuals. Taara Quest reserves the right to modify, limit, or discontinue any portion of the Services at any time.

**3. Acceptable Use and Prohibited Conduct**

Users agree to access and utilize the Service solely for lawful purposes consistent with personal growth and development. Users may not engage in any conduct or submit any content that:

- Interferes with or disrupts the Service, such as excessive data requests, unauthorized automation, or consumption of excessive resources;
- Exploits the Service for spam, phishing, or fraudulent commercial solicitations;
- Engages in or facilitates hacking, data mining, cryptocurrency mining, or other forms of malicious attacks;
- Violates the privacy of others, including scraping data or reverse engineering;
- Circumvents bans or other restrictions placed by Taara Quest.

Any prohibited conduct or breach of these guidelines may lead to immediate account suspension or termination, and Taara Quest may report serious infractions to appropriate authorities.

**4. User Content and Intellectual Property**

Users retain all rights to content they create on the platform but grant Taara Quest a royalty-free, worldwide, perpetual license to copy, display, and analyze this content to improve the Services. Content generated by Taara Quest’s AI, including templates and models, is solely owned by Taara Quest. Users may not resell or distribute any portion of Taara Quest’s intellectual property without express written consent.

- **Public Content**: Content shared publicly may be accessed, viewed, and copied by others, with Users agreeing to adhere to any third-party licensing requirements for content re-use.
- **Private Content**: Taara Quest respects the privacy of content shared within private sessions; however, Taara Quest may access such content for troubleshooting, security purposes, or as otherwise necessary to maintain service integrity.

**5. Subscriptions and Payment Terms**

Taara Quest offers monthly subscriptions, one-time packages, and in-app purchases:

- **Monthly Subscriptions**: Automatically renew unless canceled before the next billing cycle.
- **One-Time Packages**: Valid for the specified term and expire upon completion.
- **Refunds**: Refunds are granted only if the platform is unavailable for over one continuous week due to issues under Taara Quest’s control, in which case monthly subscriptions will be refunded proportionately. No other refunds are provided.

**6. Limitation of Liability and Warranty Disclaimers**

Taara Quest provides the Service on an “AS IS” and “AS AVAILABLE” basis without warranties of uninterrupted service, accuracy, or suitability for particular purposes.

- **Liability Limitations**: To the extent permitted by law, Taara Quest shall not be liable for any indirect, incidental, punitive, or consequential damages, including loss of data, revenue, or business interruption.
- **Maximum Liability**: Taara Quest’s aggregate liability, under any circumstance, shall not exceed the total amount paid by the User in the twelve (12) months preceding the claim.
- **Warranty Exclusion**: Taara Quest expressly disclaims any warranties not explicitly provided within this Agreement, including but not limited to implied warranties of merchantability or fitness for a particular purpose. This exclusion applies to damages resulting from unauthorized access, data loss, or VR-related health risks.

**7. Privacy and Data Use**

Taara Quest values User privacy. Our use of data complies with the General Data Protection Regulation (GDPR) and relevant data protection laws. Anonymized data may be used to enhance Taara Quest’s AI models and optimize the user experience. Please refer to our [Privacy Policy] for comprehensive details regarding data processing, storage, and User rights.

**8. Term, Renewal, and Termination**

- **Term**: This Agreement is effective upon the User’s first access to the Service and continues for the initial term chosen at enrollment. The Term will renew automatically for successive one-year terms unless either party provides written notice of non-renewal ninety (90) days before the end of the current term.
- **Termination**: Users may terminate this Agreement by providing written notice; no fees will be refunded upon such termination. If Taara Quest materially breaches this Agreement and fails to cure such breach within thirty (30) days after notice, the User may terminate, in which case any unearned prepaid fees will be refunded.
- **Post-Termination Obligations**: Upon termination, all rights granted under this Agreement shall cease, and Users must cease all access to Taara Quest’s platform. Taara Quest will securely delete all User data within thirty (30) days post-termination, except where retention is required by law.

**9. Content Safety and Moderation**

Taara Quest is committed to maintaining a safe environment. A “Stop and Report” function is available to report inappropriate content or conduct. Taara Quest may moderate content and suspend or terminate accounts as necessary to uphold community safety.

**10. Amendments and Notification**

Taara Quest reserves the right to modify these Terms at any time. Users will receive notification of significant changes via email, and continued use of the Service after any update will constitute acceptance of the revised Terms.

**11. Dispute Resolution and Governing Law**

All disputes shall be resolved through mediation in Leipzig, Germany. This Agreement shall be governed exclusively by the laws of Germany, without regard to conflict of law principles. Users agree to submit to the jurisdiction of the courts located in Leipzig for any necessary litigation following mediation.

**12. Miscellaneous Provisions**

- **Assignment**: Users may not assign or transfer their rights under this Agreement without Taara Quest’s written consent.
- **Severability**: Should any part of this Agreement be held invalid or unenforceable, the remaining provisions shall remain in full effect.
- **Force Majeure**: Neither party shall be liable for delays caused by unforeseen circumstances beyond their reasonable control, including natural disasters, strikes, or internet outages.

This Terms of Service, together with our Privacy Policy and other referenced documents, represents the entire agreement between Taara Quest and the User concerning the subject matter hereof.
    `;

const TermsOfService = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <NonProtectedLayout>
      <div className="px-[198px] mobile:px-4 pb-4">
        <Markdown content={markdownContent} />
      </div>
    </NonProtectedLayout>
  );
};

export default TermsOfService;

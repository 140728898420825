import React from "react";

const CustomToggler = ({ isToggled, handleToggle }) => {
  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isToggled}
        onChange={handleToggle}
      />
      <div
        className={`relative w-[84px] h-[39px] rounded-full transition-colors shadow-lg ${
          isToggled ? "bg-primaryPink" : "bg-primaryBlue"
        }`}
      >
        <div
          className={`absolute top-0 w-[39px] h-[39px] bg-white rounded-full transition-transform shadow-lg ${
            isToggled ? "translate-x-[45px]" : "translate-x-0"
          }`}
        ></div>
      </div>
    </label>
  );
};

export default CustomToggler;

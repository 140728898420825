import React from "react";
import PrimaryButton from "../button/PrimaryButton";

const ApplyForAccess = () => {
  return (
    <div className="mt-52 mobile:mt-[292px] mb-28 flex justify-center">
      <div className="w-[594px] mobile:w-[255px] flex flex-col items-center gap-[54px] mobile:gap-[26px] text-center">
        <h4 className="text-[32px] mobile:text-lg  text-primaryBlue">
          This means your investment helps break barriers and forge paths for
          women just like you, worldwide.
        </h4>
        <PrimaryButton text="Apply For Free Access" />
      </div>
    </div>
  );
};

export default ApplyForAccess;

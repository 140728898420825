import React from "react";

const InputText = () => {
  return (
    <input
      type="text"
      className="w-full !border-2 !border-primaryBlue rounded-full text-center h-12 font-nohemi"
      style={{border: "2px solid #533884"}}
    />
  );
};

export default InputText;

import React from "react";

const TextInput = ({
  name,
  type,
  isRequired,
  register,
  placeholder,
  errors,
  validateInput,
  pattern,
  maxLength,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <input
        type={type}
        name={name}
        {...register(name, {
          required: { value: isRequired, message: "This field is required" },
          ...(maxLength
            ? {
                maxLength: {
                  value: maxLength,
                  message: `Must be no more than ${maxLength} characters`,
                },
              }
            : {}),
          ...(validateInput
            ? {
                pattern: {
                  value: pattern,
                  message: "Invalid email address",
                },
              }
            : {}),
        })}
        placeholder={placeholder}
        className="rounded-full text-center bg-primaryBlue text-white h-12 font-nohemi"
        style={{ border: "2px solid white" }}
      />
      {errors[name] && (
        <p className="text-sm font-white">{errors[name]?.message}</p>
      )}
    </div>
  );
};

export default TextInput;

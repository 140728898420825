import BarriersCard from "../BarriersCard/BarriersCard";
import WhatAmbassadorsDoCard from "../WhatAmbassadorsDoCard/WhatAmbassadorsDoCard";
import "./Doing.css";

import React from "react";

const Doing = () => {
  return (
    <>
      <div className="DoingContainer">
        <div className="topSection">
          <BarriersCard />
        </div>
        <div className="DoinggraphicalBackgroundArea">
          <div className="DoingtopGraphicalArea">
            <div className="DoingtopGraphicalAreaText">
            WHAT AMBASSADORS DO?
            </div>
            <div className="DoingtopGraphicalAreaParagraph">
            We value every contribution and recognize that our ambassadors are the heartbeat of Taara Quest. Here’s how you can make a difference:
            </div>
          </div>
        </div>
        <div className="bottomSection">
          <WhatAmbassadorsDoCard />
        </div>
      </div>
    </>
  );
};

export default Doing;

import React from "react";
import CommonImage from "../LazyImage/CommonImage";

const PricingPoints = () => {
  return (
    <>
      <div className="grid grid-cols-12 mobile:col-span-1 gap-[19px] h-[355px] mobile:hidden">
        <div className="col-span-7 mobile:col-auto">
          <CommonImage
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp"
            alt="Pricing points left image"
            className="w-full h-[355px] rounded-[20px]"
          />
        </div>
        <div className="mobile:col-auto col-span-5 bg-primaryBlue rounded-[20px] text-white text-xl px-[74px] py-[83px] h-[355px]">
          <ul className="list-disc text-justify w-[380px]">
            <ListItem text="Applicants must be women aged 28-38" />
            <ListItem
              text="Currently employed in or actively seeking opportunities in the
            technology sector"
            />
            <ListItem text="Commits to providing feedback through surveys or testimonials" />
          </ul>
        </div>
      </div>
      <div className="hidden mobile:flex w-full h-full relative">
        <CommonImage
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp"
          alt="Pricing points left image"
          className="w-full h-[225px] rounded-[20px]"
        />
        <div className="absolute w-full h-full text-white bg-[rgba(0,0,0,0.3)] rounded-[20px] px-8 py-12">
          <ul className="list-disc text-justify w-[235px]">
            <ListItem text="Applicants must be women aged 28-38" />
            <ListItem
              text="Currently employed in or actively seeking opportunities in the
            technology sector"
            />
            <ListItem text="Commits to providing feedback through surveys or testimonials" />
          </ul>
        </div>
      </div>
    </>
  );
};

const ListItem = ({ text }) => (
  <li className="text-xl mobile:text-xs font-nohemi font-normal text-justify leading-8 mobile:leading-6">
    {text}
  </li>
);

export default PricingPoints;

import "./TaaraAmbassador.css";

import React from "react";
import brainIcon from "../../../images/ambassadors/brain.svg";
import gogglesIcon from "../../../images/ambassadors/goggles.svg";
import loveTimerIcon from "../../../images/ambassadors/love_timer.svg";

const taaraAmbassadorRoles = [
  {
    icon: brainIcon,
    text: "You're curious to learn more about AI tech.",
  },
  {
    icon: gogglesIcon,
    text: "You're interested in roleplay in virtual worlds.",
  },
  {
    icon: loveTimerIcon,
    text: "Can dedicate some time to helping us grow Taara regularly (how much and when is up to you).",
  },
];

const TaaraAmbassador = () => {
  return (
    <div className="TaaraAmbassadorContainer">
      <div className="TaaraAmbassadorHeading">YOU’RE A TAARA AMBASSADOR IF...</div>
      <div className="TaaraAmbassadorGrids">
        {taaraAmbassadorRoles.map((taaraAmbassadorRole, idx) => (
          <div className="TaaraAmbassadorGrid" key={idx}>
            <img
              src={taaraAmbassadorRole.icon}
              alt={taaraAmbassadorRole.text}
              width={70}
              height={70}
            />
            <p className="TaaraAmbassadorText">{taaraAmbassadorRole.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaaraAmbassador;

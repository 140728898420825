import React, { useState } from "react";
import TextInput from "../form/TextInput";
import CustomSelect from "../CustomSelect/CustomSelect";
import PrimaryButton from "../../button/PrimaryButton";
import TextareaInput from "../form/TextareaInput";
import { useForm } from "react-hook-form";
import clsxm from "../../../utility/clsxm";
import useMutationInstance from "../../../hooks/useMutationInstance";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";

const growthFocusOptions = [
  "Navigating Conflict",
  "Facilitate Change",
  "Saying No",
  "Negotiating Effectively",
  "Navigating Emotional Responses",
];

const settingOptions = ["Office", "Hallway", "Lobby", "Cafeteria"];

const CreateScenarioForm = ({ appearances = [], closeModalAction }) => {
  const queryClient = useQueryClient();
  const { user, token } = useSelector((state) => state.auth);
  const [selectedAppearanceId, setSelectedAppearanceId] = useState(() =>
    appearances.length > 0 ? appearances[0]?._id : ""
  );
  const handleSelectAppearance = (id) => setSelectedAppearanceId(id);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm();

  const onError = (error) => {
    const errorResponse = error?.response?.data;
    // console.log(errorResponse);
    toast.error(errorResponse?.message || "Something went wrong");
  };

  const onSuccess = (response) => {
    console.log("data", response);
    queryClient.invalidateQueries({ queryKey: ["get-custom-quests"] });
    closeModalAction();
  };
  const { mutate, isPending } = useMutationInstance({
    onSuccess,
    onError,
  });

  const onSubmit = (data) => {
    console.log(data);
    mutate({
      url: `${process.env.REACT_APP_BACKEND}/custom/scenarios`,
      data: {
        ...data,
        appearanceId: selectedAppearanceId,
      },
      token,
    });
  };

  return (
    <form className="flex flex-col gap-7" onSubmit={handleSubmit(onSubmit)}>
      {/* Title */}
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">Title</div>
        <div className="col-span-2">
          <TextInput
            name="title"
            type="text"
            placeholder="Please give the scenario a title"
            isRequired={true}
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">Roleplay Partner Name</div>
        <div className="col-span-2">
          <TextInput
            name="roleplayPartnerName"
            type="text"
            placeholder="Name of the roleplay partner"
            isRequired={true}
            register={register}
            errors={errors}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">Growth Focus</div>
        <div className="col-span-2">
          <CustomSelect
            name="growthFocus"
            placeholder="Select Topic"
            register={register}
            errors={errors}
            setValue={setValue}
            isRequired={true}
            options={growthFocusOptions}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          What's the situation you want to roleplay?
        </div>
        <div className="col-span-2">
          <TextareaInput
            name="situation"
            placeholder="Describe the situation"
            register={register}
            errors={errors}
            isRequired={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">
          What personality shall the counterpart have?
        </div>
        <div className="col-span-2">
          <TextareaInput
            name="personality"
            placeholder="Describe the personality"
            register={register}
            errors={errors}
            isRequired={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">Setting</div>
        <div className="col-span-2">
          <CustomSelect
            name="environment"
            placeholder="Please select an environment"
            register={register}
            errors={errors}
            setValue={setValue}
            isRequired={true}
            options={settingOptions}
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4">
        <div className="col-span-1">Select appearance</div>
        <div className="col-span-2 flex gap-2">
          {appearances.map((appearance) => (
            <div
              className={clsxm(
                "p-1 flex items-center rounded-full cursor-pointer",
                selectedAppearanceId === appearance._id
                  ? "bg-primaryPink"
                  : "bg-white"
              )}
              key={appearance._id}
              onClick={() => handleSelectAppearance(appearance._id)}
            >
              <img
                alt="appearance"
                src={appearance?.iconUrl}
                width={64}
                height={64}
                className="rounded-full"
              />
            </div>
          ))}
        </div>
      </div>

      <div className="w-full flex justify-center">
        <PrimaryButton
          text="Save"
          isLoading={isPending}
          disable={!isValid || !selectedAppearanceId}
          className="w-48"
        />
      </div>
      <Toaster />
    </form>
  );
};

export default CreateScenarioForm;

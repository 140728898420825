import React from "react";
import "./BlogCard.css";
import { Link } from "react-router-dom";

const BlogCard = ({ blog }) => {
  return (
    <Link
      to={`/blog/${blog.id}`}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      className="blog-card-container"
    >
      <img
        src={blog.titleImage}
        alt="blog-image"
        loading="lazy"
        className="blog-card-image"
      />
      <div className="blog-card-content">
        <div className="tags">
          {blog.tags.map((tag, idx) => (
            <div key={idx} className="each-tag">
              {tag}
            </div>
          ))}
        </div>
        <h3 className="blog-card-heading">{blog.title}</h3>
        <div className="blog-by">by {blog.author}</div>
      </div>
    </Link>
  );
};

export default BlogCard;

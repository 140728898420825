import React, { useState, useEffect } from "react";
import { encryptData } from "../../utility/encryption";
import axios from "axios";
import "./AskTaaraForm.css";
import LazyImage from "../LazyImage/LazyImage";
import roundLogo from "../../images/round-logo.svg";
import useScreenDetector from "../../hooks/useScreenDetector";

const AskTaaraForm = () => {
  const { isMobile } = useScreenDetector();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [query, setQuery] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    setErrorMessage("");
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleTaaraFormSubmit = () => {
    if (name === "" || email === "" || query === "" || isSending) {
      // console.log("Missed");
      return;
    } else {
      setIsSending(true);
      const payload = {
        name,
        email,
        query,
      };
      const URL = `${process.env.REACT_APP_BACKEND}/waitlist/query`;
      // console.log("CP 1");
      const token = encryptData(JSON.stringify(payload));
      // console.log(token);
      // Reset Variables
      axios
        .post(URL, { token })
        .then((response) => {
          setErrorMessage(response.data.message);
          setIsSending(false);
          setName("");
          setEmail("");
          setQuery("");
        })
        .catch((error) => {
          // console.log("Error:", error);
          setErrorMessage(error.response.data.message);
          setIsSending(false);
          setName("");
          setEmail("");
          setQuery("");
        });
    }
  };
  return (
    <>
      <div className="AskTaaraArea">
        <div className="TaaraFormContainer">
          <div className="LeftAskTaaraArea">
            <div className="taaraRoundLogo">
              <LazyImage
                src={roundLogo}
                alt="Taara Quest Logo"
                width={isMobile ? "80px" : "150px"}
                height={isMobile ? "80px" : "150px"}
                bg="none"
              />
            </div>
            <div className="LeftAskTaaraTextArea">
              <div className="LeftAskTaaraTitle">ASK TAARA</div>
              <div className="LeftAskTaaraText">
                Any questions, ideas,
                <br />
                feedbacks you'd like to
                <br />
                share with Taara?
              </div>
              <div
                className="LeftAskTaaraButton"
                onClick={handleTaaraFormSubmit}
              >
                Ask Taara
              </div>
            </div>
          </div>
          <div className="RightAskTaaraArea">
            <div className="taaraFormComponents">
              <input
                type="text"
                placeholder="Full Name"
                value={name}
                onChange={(e) => {
                  if (!isSending) {
                    setName(e.target.value);
                  }
                }}
              />
              <input
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => {
                  if (!isSending) {
                    setEmail(e.target.value);
                  }
                }}
              />
              <textarea
                value={query}
                placeholder="Your Message"
                onChange={(e) => {
                  if (!isSending) {
                    setQuery(e.target.value);
                  }
                }}
                wrap
              ></textarea>
              {errorMessage ? (
                <>
                  <div className="errorMessage">{errorMessage}</div>
                </>
              ) : (
                <></>
              )}
              <div
                className="RightAskTaaraButtonMobile"
                onClick={handleTaaraFormSubmit}
              >
                Ask Taara
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AskTaaraForm;

import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import "./styles.css";
import PrimaryButton from "../../button/PrimaryButton";

const TranscriptModal = ({ appearances, closeModalAction, isOpen = false }) => (
  <Dialog.Root open={isOpen}>
    <Dialog.Portal>
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent pt-[96px] pb-[48px] px-[42px] flex flex-col gap-12">
        {/* Content goes here */}
        <div className="flex flex-col gap-7">
          <h3 className="text-[40px] font-audioWide text-center">Transcript</h3>
          <p className="text-base text-[#424242] px-20">
            Here you find the transcript of the scenario
          </p>
        </div>
        {/* Each Session */}
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-1">Session</div>
            <div className="col-span-3">13.09.2024 18:23</div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-1">Text</div>
            <div className="col-span-3 flex flex-col gap-2">
              {/* Each Message */}
              <div className="flex flex-col">
                <p>Luise:</p>
                <p>I want to talk about my salary again</p>
              </div>
              <div className="flex flex-col">
                <p>Bob:</p>
                <p>I want to talk about my salary again</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <PrimaryButton
            text="Go back"
            className="w-48 bg-primaryBlue"
            onClick={closeModalAction}
          />
        </div>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default TranscriptModal;

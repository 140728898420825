export const instagramCards = [
     {
       image: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/taara/insta-posts/1.webp",
     },
     {
       image: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/taara/insta-posts/2.webp",
     },
     {
       image: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/taara/insta-posts/3.webp",
     },
   ];
import { TextField } from "@radix-ui/themes";
import React from "react";

const TextInput = ({
  name,
  type = "text",
  isRequired = false,
  register,
  placeholder,
  errors,
  validateInput = false,
  pattern,
  maxLength,
}) => {
  return (
    <div className="flex flex-col">
      <TextField.Root
        size="3"
        placeholder={placeholder}
        type={type}
        name={name}
        className={`px-6 py-2 rounded-lg ${
          errors[name] ? "border-red-500" : ""
        }`}
        {...register(name, {
          required: isRequired
            ? { value: true, message: "This field is required" }
            : false,
          ...(maxLength
            ? {
                maxLength: {
                  value: maxLength,
                  message: `Must be no more than ${maxLength} characters`,
                },
              }
            : {}),
          ...(validateInput && pattern
            ? {
                pattern: {
                  value: pattern,
                  message: "Invalid input format",
                },
              }
            : {}),
        })}
      />
      {errors && (
        <span className="text-red-500 text-sm mt-1">
          {errors[name]?.message}
        </span>
      )}
    </div>
  );
};

export default TextInput;

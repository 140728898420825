import React, { useState } from "react";
import PrimaryButton from "../../button/PrimaryButton";
import "./PriceCard.css";
import productDetailPoints from "../../../data/productDetailPoints";

const PriceCard = ({ prodId, productName, price = 0 }) => {
  const [isHovered, setIsHovered] = useState(false);
  const onMouseOver = () => setIsHovered(true);
  const onMouseOut = () => setIsHovered(false);
  return (
    <div
      className={`col-span-1 flex flex-col justify-between px-9 py-12 rounded-[20px] shadow-[0px_4px_15px_rgba(0,0,0,0.1)] ${
        isHovered && "bg-primaryBlue text-white"
      }h`}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <h3
            className={`text-primaryBlue font-bold text-xl mobile:text-base ${
              isHovered && " text-white"
            }`}
          >
            {productName}
          </h3>
          <div className="text-[65px] mobile:text-[56px] text-[#E2B44B] m-0 p-0">
            ${price}
          </div>
        </div>
        <ul className="list-none flex flex-col gap-3">
          {productDetailPoints[prodId]?.map((point, idx) => (
            <li className="flex items-center gap-2 pricePointsIcon" key={idx}>
              <i className="fa-solid fa-circle-check"></i>
              <span
                className={`text-[13px] mobile:text-[12px] ${
                  isHovered && "text-white"
                }`}
              >
                {point}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <PrimaryButton text="Sign Up Now" className="mt-8" />
    </div>
  );
};

export default PriceCard;

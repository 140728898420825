import rehypeRaw from "rehype-raw";
import LazyImageObjectFit from "../../components/LazyImage/LazyImageObjectFit";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Markdown = ({ content }) => {
  return (
    <div className="blog-markdown">
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]} // Enable HTML parsing
        components={{
          h1: ({ node, ...props }) => (
            <h1
              className="text-[43px] mobile:text-[20px] leading-11 mobile:leading-8 my-8 text-center text-primaryPink font-audioWide"
              {...props}
            />
          ),
          h2: ({ node, ...props }) => (
            <h2
              className="text-[35px] mobile:text-lg my-4 text-primaryBlue font-nohemi"
              {...props}
            />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-[32px] mobile:text-[18px] my-2" {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 className="text-[24px] my-2 mobile:text-sm" {...props} />
          ),
          p: ({ node, ...props }) => (
            <p
              className="text-md my-8 mobile:my-4 leading-8 mobile:leading-6 font-nohemi mobile:text-[12px]"
              {...props}
            />
          ),
          p1: ({ node, ...props }) => (
            <p
              className="text-md my-8 mobile:my-4 leading-7 mobile:leading-5 font-nohemi text-center mobile:text-[12px]"
              {...props}
            />
          ),
          img: ({ node, ...props }) => (
            <LazyImageObjectFit
              className="my-14 mobile:my-7 rounded-[20px]"
              src={props.src}
              alt={props.alt}
            />
          ),
          ul: ({ unordered, ...props }) => (
            <ul
              className="text-sm mobile:text-[12px] flex flex-col gap-4 mobile:gap-2 my-4 mobile:my-2 ml-8 list-disc leading-6"
              {...props}
            />
          ),
        }}
      />
    </div>
  );
};

export default Markdown;

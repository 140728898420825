import React, { useState } from "react";
import "./EventCards.css";
import ReactCardFlip from "react-card-flip";

import teamwork from "../../../images/ambassadors/teamwork.svg";
import movieTicket from "../../../images/ambassadors/movie_ticket.svg";
import user from "../../../images/ambassadors/user.svg";

const EventCards = () => {
  const [eventsCardState, setReactCardState] = useState({
    event1: {
      frontHeading: "Special Events And Coaching",
      backHeading: "Gain Access To Exclusive Events And Coaching Sessions.",
      icon: movieTicket,
      event: "event1",
    },
    event2: {
      frontHeading: "Collaborative Innovation",
      backHeading: "Work With Us To Strategize & Innovate",
      icon: teamwork,
      event: "event2",
    },
    event3: {
      frontHeading: "Inclusive Women Community",
      backHeading:
        "Network With An Amazing Circle Of Women In Tech Around The World",
      icon: user,
      event: "event3",
    },
  });

  const toggleFlip = (cardKey) => {
    setReactCardState((prevState) => ({
      ...prevState,
      [cardKey]: {
        ...prevState[cardKey],
        flipped: !prevState[cardKey].flipped,
      },
    }));
  };

  const handleMouseEnter = (cardKey) => toggleFlip(cardKey);
  const handleMouseLeave = (cardKey) => toggleFlip(cardKey);
  return (
    <div className="EventstepCards">
      {Object.keys(eventsCardState)?.map((key, idx) => {
        const event = eventsCardState[key];
        return (
          <div
            key={idx}
            className="EventstepCard"
            onMouseEnter={() => handleMouseEnter(event.event)}
            onMouseLeave={() => handleMouseLeave(event.event)}
          >
            <ReactCardFlip isFlipped={event.flipped} flipDirection="horizontal">
              {/* <div className="frontCard"> */}
              <div className="EventstepCardContainer">
                <div className="EventfrontStepCard">
                  <div className="EventtopStepCardIcon">
                    <img
                      src={event.icon}
                      alt={event.frontHeading}
                      width={70}
                      height={70}
                    />
                  </div>
                </div>

                <div className="EventbelowStepCard">
                  <div className="EventbelowStepCardTitle">
                    {event.frontHeading}
                  </div>
                </div>
              </div>
              {/* </div> */}
              <div className={`backCard`}>
                <div className="EventstepCardContainerFlipped">
                  <div className="EventfrontStepCard">
                    <div className="EventtopStepCardIcon">
                      <img
                        src={event.icon}
                        alt={event.backHeading}
                        width={70}
                        height={70}
                        className="hide-on-mobile"
                      />
                    </div>
                  </div>

                  <div className="EventbelowStepCard">
                    <div className="EventbackCardbelowStepCardTitle">
                      {event.backHeading}
                    </div>
                  </div>
                </div>
              </div>
            </ReactCardFlip>
          </div>
        );
      })}
    </div>
  );
};

export default EventCards;

import React from "react";
import clsxm from "../../../utility/clsxm";

const EachBillingDetails = ({ label, value, className }) => {
  return (
    <div className="flex flex-row gap-2 mobile:flex-col">
      <p className={clsxm("w-[116px] leading-10", className)}>{label}</p>
      <p className="ml-16 mobile:ml-0 leading-10">{value}</p>
    </div>
  );
};

export default EachBillingDetails;

const productDetailPoints = {
  free: [
    "Access to introductory “Confidence” quest",
    "Basic coaching with Taara",
    "Access to Community",
    "Get each additional Quest for $9.99",
  ],
  prod_R3hDmeiZZh49My: [
    "Play up to 3 quests",
    "20 roleplays per month",
    "1hr of advanced coaching with Taara",
    "1 self-defined scenario with  roleplaying character to fit your personal challenge",
    "3 custom scenario conversations per month",
    "Access to community with events, Networking and peer support",
  ], // $29
  prod_R3hH8As1TOwgVk: [
    "Access all Quests and become a champion",
    "unlimited roleplays",
    "Targeted leadership coaching with Taara",
    "unlimited self-defined scenarios",
    "10 custom scenario conversations per month",
    "Tailored growth guide",
    "Access to coaching team and free introductory call",
    "Access to exclusive community events",
  ], // $79
  prod_R0RWrJC3R5wFGR: [
    "Access to all quests",
    "40 roleplays per month",
    "Basic coaching with Taara",
    "Access to community with events, Networking and peer support",
  ], //$45
  prod_R3hKncEnxOOjfG: [
    "5 self-defined scenario with  roleplaying character to fit your personal challenge",
    "50 custom scenario conversations per month",
    "Access to community with exclusive events, Networking and peer support",
    "Additional conversation credits for only $3",
  ], // $299
  prod_R3hNg0TrAGnwJk: [
    "Access to all Quests",
    "no roleplay limit",
    "Targeted leadership coaching with Taara",
    "Tailored growth guide",
    "5 self-defined scenario with  roleplaying character to fit your personal challenge",
    "50 custom scenario conversations per month",
    "Get access to VIP coaching content",
  ], // $499
};

export default productDetailPoints;

import React, { useEffect } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { useParams } from "react-router-dom";
import blogs from "../../data/blogs";

import RelatedBlogs from "../Blog/RelatedBlogs";
import Markdown from "../../components/Markdown/Markdown";

const BlogDetails = (props) => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <NonProtectedLayout>
      <div className="w-full flex flex-col gap-[30px] px-[30px] mobile:px-5">
        <div className="w-[60%] m-auto mobile:w-[100%]">
          <Markdown content={blogs?.find((blog) => blog.id == id)?.content} />
        </div>
        <RelatedBlogs currentBlogId={id} />
      </div>
    </NonProtectedLayout>
  );
};

export default BlogDetails;

import React from "react";
import "./Blog.css";
import SpaceWrapper from "../../components/wrapper/SpaceWrapper/SpaceWrapper";
import BlogCard from "../../components/blog/BlogCard/BlogCard";
import blogs from "../../data/blogs";

const RelatedBlogs = ({currentBlogId}) => {
  return (
    <div className="related-content-container">
      <SpaceWrapper>
        <div className="all-posts-container">
          <div className="all-posts-header">
            <div className="heading-container">
              <h3 className="all-posts-heading">Related Content</h3>
            </div>
          </div>
        </div>
        <div className="blog-list-container">
          {blogs.filter(blog => blog.id != currentBlogId).map((blog, idx) => (
            <BlogCard key={idx} blog={blog} />
          ))}
        </div>
      </SpaceWrapper>
    </div>
  );
};

export default RelatedBlogs;

import React, { useEffect } from "react";
import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";
import "./About.css";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import LazyImage from "../../components/LazyImage/LazyImage";


import thirdSectionImage from "../../images/about/secondImage.webp";
import forthSectionImage from "../../images/about/thirdImage.webp";
import useScreenDetector from "../../hooks/useScreenDetector";
import Header from "../../components/Header/Header";

const profiles = [
  {
    name: "Kamilla Faszylova",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/kamilla.webp",
    designation: "Head of Marketing",
  },
  {
    name: "Charlie Sambo",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/charlie.webp",
    designation: "Community Manager",
  },
  {
    name: "Bakhtawar Chaudary",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/bakhtawar.webp",
    designation: "Trainee Marketing & Content Management",
  },
  {
    name: "Shiza Durrani",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/shiza.webp",
    designation: "Data Management",
  },
  {
    name: "Athanasia Symeonidou",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/athanasia.webp",
    designation: "Advisor XR Technology",
  },
  {
    name: "Aisyah Taqwim",
    src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/aisyah.webp",
    designation: "Trainee Community Building",
  },
];

const About = () => {
  const { isMobile } = useScreenDetector();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <NonProtectedLayout>
      <Header
        title="About Taara Quest: Innovative Leadership Training & AI Tools"
        description="Discover Taara Quest’s mission and values. We empower leaders with
innovative training and AI learning tools to help women reach their
potential."
        keywords="ai learning tools"
      />
      <div className="AboutContainer">
        <div className="FirstSection">
          <div className="FirstSectionText">
            We're on a mission to help women <br />
            bring their light into the world
            <br />
            <span>undimmed</span>
          </div>
        </div>
        {/* IMAGES RED AND BLUE SPIRAL  */}
        <div className="SecondSection">
          <div className="SecondSectionText">
            The current systems make
            <br />
            us sad and hold us back
          </div>

          <div className="backgroundHalf blueHalf"></div>
          <div className="backgroundHalf redHalf"></div>
        </div>
        <div className="ThirdSection">
          <div className="ThirdSectionLeftElement">
            <div className="ThirdSectionLeftElementHeader">
              {isMobile ? (
                <>REDEFINING LEADERSHIP</>
              ) : (
                <>
                  REDEFINING
                  <br />
                  LEADERSHIP
                </>
              )}
            </div>
            <span>
              Taara Quest helps women lead authentically at any stage of life by
              breaking barriers, offering innovative tech tools, and building a
              strong community.
            </span>
          </div>
          <div className="ThirdSectionRightElement">
            <LazyImage
              src={thirdSectionImage}
              alt="third-section-image"
              bg="none"
            />
          </div>
        </div>
        <div className="FourthSection">
          <div className="FourthSectionLeftElement">
            <LazyImage
              src={forthSectionImage}
              alt="forth-section-image"
              bg="none"
            />
          </div>
          <div className="FourthSectionRightElement">
            <div className="FourthSectionRightElementHeader">
              {isMobile ? (
                <>IMMERSIVE UNIVERSE</>
              ) : (
                <>
                  IMMERSIVE
                  <br /> UNIVERSE
                </>
              )}
            </div>
            <span>
              Our immersive hub and global community allow women to get unstuck,
              show up confidently, and speak fearlessly.
            </span>
          </div>
        </div>
        <div className="FifthSection">
          <div className="FifthSectionTopText">
            Taara Quest began with one idea:
          </div>
          <div className="FifthSectionBottomText">
            To use AI and gaming
            <br />
            technology as a force to
            <br />
            supercharge women's growth.
          </div>
        </div>
        <div className="SixthSection">
          MEET THE MINDS
          <br />
          <span>behind the mission</span>
        </div>
        <div className="SeventhSection">
          <div className="SeventhSectionImageElement">
            <LazyImage
              src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/team/luise.webp"
              alt="Dr. Luise Frohberg"
              width="100%"
              height="100%"
              bg="none"
            />
          </div>
          <div className="SeventhSectionTextElement">
            <div className="SeventhSectionTextElementHeader">
              Dr. Luise Frohberg
            </div>
            <div className="SeventhSectionTextElementContent">
              Luise is a former political leader and women leadership advocate.
              As a serial-entrepreneur, published author, and innovation expert
              she founded Taara Quest in 2021 after joining her sons in gaming
              worlds like Minecraft during the Covid-19-lockdown.
            </div>
          </div>
        </div>

        {/**  Team members section **/}
        <div className="EightSection">
          <div className="profile-grid">
            {profiles?.map((profile, idx) => (
              <div className="profile-card" key={idx}>
                <LazyImage
                  src={profile.src}
                  alt={profile.src}
                  width={"100%"}
                  height={isMobile ? "308px" : "100%"}
                  className="team-image"
                  bg="none"
                />
                <p>
                  {profile.name}
                  <br />
                  <span>{profile.designation}</span>
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="NinthSection">
          JOIN OUR JOURNEY
          <br />
          <span>at Taara Quest</span>
        </div>
        <div className="TenthSection">
          <div className="LeftTenthSection relative">
            <LazyImage
              src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/about/fourthImage.webp"
              alt="left-tenth-section"
              bg="none"
              height={isMobile ? "225px" : "100%"}
            />
            <div class="absolute inset-0 bg-black bg-opacity-50 justify-center items-center hidden mobile:flex flex-col gap-4 rounded-[20px]">
              <p className="text-[12px] text-white w-[65%]">
                Dive into a world of innovation and teamwork! We're looking for
                passionate individuals for internships and talented
                professionals for open roles
              </p>
              <span className="text-[12px] text-white w-[50%] font-bold">
                Interested in partnering?
                <br />
                We'd love to hear from you!
              </span>
            </div>
          </div>
          <div className="rightTenthSection">
            <p>
              Dive into a world of innovation and teamwork! We're looking for
              passionate individuals for internships and talented professionals
              for open roles
            </p>
            <span>Interested in partnering? We'd love to hear from you!</span>
          </div>
        </div>
        <AskTaaraForm />
      </div>
    </NonProtectedLayout>
  );
};

export default About;

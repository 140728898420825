import CryptoJS from "crypto-js";

export const encryptData = (textData) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const encryptedData = CryptoJS.AES.encrypt(textData, secretKey).toString();

  return encryptedData;
};

export const decryptData = (encryptedText) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const decryptedData = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(
    CryptoJS.enc.Utf8
  );

  return decryptedData;
};

import "./BarriersCard.css";

import React from 'react'

const BarriersCard = () => {
  return (
    <div className="BarriersCardContainer">
        <div className="BarriersCardContainerContent">
          <h3 className="BarriersHeading">Imagine a world where women leverage AI to break barriers and build bridges.</h3>
          <p className="BarriesDescription">As a Taara Ambassador, you’re not just imagining it—you’re living it. You’ll be at the forefront, helping us test and refine tools that empower women globally.</p>
        </div>
    </div>
  )
}

export default BarriersCard
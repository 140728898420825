import React, { useState } from "react";
import "./FiltersModal.css";
import crossIcon from "../../../images/blog/cancel.svg";
import FilterTag from "../FilterTag/FilterTag";

function FiltersModal({
  filterTags,
  closeModal,
  handleMobileFilters,
  filters,
}) {
  const [selectedFilters, setSelectedFilters] = useState(filters || []);

  const handleToggleFilter = (filterOption) => {
    const currentFilters = [...selectedFilters];
    if (selectedFilters.includes(filterOption)) {
      const filterIndex = currentFilters.indexOf(filterOption);
      currentFilters.splice(filterIndex, 1);
      setSelectedFilters(currentFilters);
    } else {
      setSelectedFilters((prev) => [...prev, filterOption]);
    }
  };

  const handleFilters = () => {
    handleMobileFilters(selectedFilters);
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Filters</h2>
          <div className="cancal-icon" onClick={closeModal}>
            <img src={crossIcon} alt="cancel" />
          </div>
        </div>
        <div className="filter-tags">
          {filterTags?.map((filterTag, idx) => (
            <FilterTag
              key={idx}
              tag={filterTag}
              isSelected={selectedFilters.includes(filterTag)}
              onClick={() => handleToggleFilter(filterTag)}
            />
          ))}
        </div>
        <button onClick={handleFilters}>Apply</button>
      </div>
    </div>
  );
}

export default FiltersModal;

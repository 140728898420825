import React, { useState } from "react";
import "./FAQAccordian.css";

const FAQAccordian = ({ question, answer }) => {
  const [showMessage, setShowMessage] = useState(false);
  const handleShowMessage = () => {
    setShowMessage((prev) => !prev);
  };
  return (
    <>
      <div
        className={`accordian-container ${showMessage && "opened"}`}
        onClick={handleShowMessage}
      >
        <div className="question">
          <span className="plus-icon">{showMessage ? "-" : "+"}</span>
          {question}
        </div>
      </div>
      {showMessage && (
        <div className={`accordian-faq-answer ${!showMessage && "hidden"}`}>
          {answer}
        </div>
      )}
    </>
  );
};

export default FAQAccordian;

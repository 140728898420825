import React from "react";
import "./FilterTag.css";

const FilterTag = ({ tag, isSelected, onClick }) => {
  return (
    <div
      className={`tag-container ${isSelected && "selected"}`}
      onClick={onClick}
    >
      {tag}
    </div>
  );
};

export default FilterTag;

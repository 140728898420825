import LazyImage from "../../LazyImage/LazyImage";
import "./WhatAmbassadorsDoCard.css";

import React from "react";

const whatAmbassadorsDoContent = [
  {
    heading: "Testing and Development",
    description:
      "Participate in beta testing our products and providing invaluable feedback to shape their development.",
  },
  {
    heading: "User Experience",
    description:
      " Share your insights on the usability and effectiveness of our tools, helping us enhance the user experience.",
  },
  {
    heading: "Marketing & Advocacy",
    description:
      "Help spread the word about Taara Quest through social media, word of mouth, and other channels.",
  },
  {
    heading: "Community Building",
    description:
      "Engage with our community, offering support and encouragement to your fellow members.",
  },
];

const WhatAmbassadorsDoCard = () => {
  return (
    <div className="WhatAmbassadorsDoCardContainer">
      {/* <img
        src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/What+ambassadors+do.webp"
        alt="What AmbassadorsDo?"
        className="WhatAmbassadorsDoLeftImage"
      /> */}
      <LazyImage
        src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/What+ambassadors+do.webp"
        alt="What AmbassadorsDo?"
        className="WhatAmbassadorsDoLeftImage"
        bg="none"
      />
      <div className="WhatAmbassadorsDoRightSideContainer">
        <div className="WhatAmbassadorsDoContent">
          {whatAmbassadorsDoContent.map((item, idx) => (
            <p className="WhatAmbassadorsDo" key={idx}>
              <span className="ContentHeading">{item.heading}:</span>{" "}
              {item.description}
            </p>
          ))}
        </div>
        <div className="WhatAmbassadorsDoMobileContainer">
          {whatAmbassadorsDoContent.map((item, idx) => (
            <div className="WhatAmbassadorsDoMobile" key={idx}>
              <h4 className="ContentHeadingMobile">{item.heading}</h4>
              <p className="WhatAmbassadorsDoMobileDescription">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhatAmbassadorsDoCard;

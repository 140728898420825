import React, { useState, useEffect } from "react";
import * as Select from "@radix-ui/react-select";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import clsxm from "../../../utility/clsxm";
import "./styles.css";


const CustomSelect = ({
  name,
  placeholder,
  register,
  setValue,
  errors,
  isRequired,
  options
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    register(name, {
      required: isRequired
        ? { value: true, message: "This field is required" }
        : false,
    });
  }, [name, register, isRequired]);

  const handleValueChange = (value) => {
    setSelectedValue(value);
    setValue(name, value);
  };

  return (
    <div className="flex flex-col gap-1">
      <Select.Root value={selectedValue} onValueChange={handleValueChange}>
        <Select.Trigger className="SelectTrigger" aria-label={name}>
          <div className="w-full flex items-center justify-between px-6">
            <Select.Value
              placeholder={placeholder}
              style={{ fontSize: "20px" }}
            />
            <Select.Icon className="SelectIcon">
              <ChevronDownIcon className="w-6 h-6 text-[#4C4C4C]" />
            </Select.Icon>
          </div>
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className="SelectContent">
            <Select.ScrollUpButton className="SelectScrollButton">
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport className="SelectViewport flex flex-col gap-2">
              <Select.Group>
                {options.map((option) => (
                  <SelectItem key={option} value={option}>
                    {option}
                  </SelectItem>
                ))}
              </Select.Group>
            </Select.Viewport>
            <Select.ScrollDownButton className="SelectScrollButton">
              <ChevronDownIcon />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      {errors[name] && (
        <p className="text-sm text-red-500 mt-1">{errors[name]?.message}</p>
      )}
    </div>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={clsxm("SelectItem", className)}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="SelectItemIndicator">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default CustomSelect;

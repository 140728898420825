import React, { useEffect, useRef, useState } from "react";

// Custom Lazy Image Component
const UpdatedLazyImageObjectFit = ({ src, alt, className }) => {
  const [isInView, setIsInView] = useState(false); // Track if image is in viewport
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true); // Image is in viewport
            observer.unobserve(entry.target); // Stop observing once the image is loaded
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: "0px", // Load image as soon as it enters the viewport
        threshold: 0.1, // Trigger when 10% of the image is visible
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current); // Start observing the image
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current); // Clean up the observer on unmount
      }
    };
  }, []);

  return (
    <div ref={imgRef} className={className}>
      {isInView && (
        <img src={src} alt={alt} className="w-full h-[712px] object-cover" />
      )}
    </div>
  );
};

export default UpdatedLazyImageObjectFit;

import React, { useEffect, useMemo, useState } from "react";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import { useQueryInstance } from "../../hooks/useQueryInstance";
import CustomToggler from "../../components/pricing/CustomToggler";
import PrimaryHeading from "../../components/primitives/PrimaryHeading";
import PriceCard from "../../components/pricing/PriceCard/PriceCard";
import EmpowerHerAccess from "../../components/pricing/EmpowerHerAccess/EmpowerHerAccess";
import ApplyForAccess from "../../components/pricing/ApplyForAccess";

const Pricing = () => {
  const [isMontlyPurchasableProducts, setIsMonthlyPurchasableProducts] =
    useState(true);

  const handleToggleMontlyPurchasableProducts = () => {
    setIsMonthlyPurchasableProducts((prev) => !prev);
  };

  const { data, error, isFetching } = useQueryInstance(
    "get-all-products",
    "/subscription/prices"
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const products = useMemo(() => {
    const RECURRING_PRODUCT_IDS = [
      "prod_R3hDmeiZZh49My",
      "prod_R3hH8As1TOwgVk",
    ];
    const ONE_TIME_PRODUCT_IDS = [
      "prod_R0RWrJC3R5wFGR",
      "prod_R3hKncEnxOOjfG",
      "prod_R3hNg0TrAGnwJk",
    ];

    const getProductIds = () =>
      isMontlyPurchasableProducts
        ? RECURRING_PRODUCT_IDS
        : ONE_TIME_PRODUCT_IDS;

    const findPriceDetails = (productKey) => {
      const defaultPriceId = productMap[productKey]?.default_price;
      return price.find((priceItem) => priceItem.id === defaultPriceId) ?? {};
    };

    const mapProductDetails = (productsMap) => {
      return Object.keys(productsMap)
        .filter((productKey) => getProductIds().includes(productKey))
        .map((productKey) => ({
          ...productMap[productKey],
          price: findPriceDetails(productKey),
        }))
        .sort(
          (product1, product2) =>
            product1.price.unit_amount - product2.price.unit_amount
        );
    };

    const productMap = data?.data?.productMap;
    const price = data?.data?.price.data;

    return productMap ? mapProductDetails(productMap) : [];
  }, [data, isMontlyPurchasableProducts]);

  return (
    <NonProtectedLayout>
      <div className="flex flex-col gap-[30px] px-[30px] mobile:px-4">
        <div className="flex flex-col items-center gap-4">
          <PrimaryHeading heading="Pricing" />
          <div className="w-84 flex gap-6 items-center justify-between">
            <p className="w-48 flex justify-end">One Time</p>
            <CustomToggler
              isToggled={isMontlyPurchasableProducts}
              handleToggle={handleToggleMontlyPurchasableProducts}
            />
            <p className="w-48">Monthly</p>
          </div>
        </div>
        {isFetching && (
          <div
            className="text-center h-1/2 flex items-center justify-center
        "
          >
            Loading...
          </div>
        )}
        <div className="px-16 mobile:px-0 grid grid-cols-3 mobile:grid-cols-1 gap-4 mobile:gap-5">
          {products.length
            ? isMontlyPurchasableProducts && (
                <PriceCard productName="Free Explorer" prodId="free" />
              )
            : null}
          {products.map((product) => (
            <PriceCard
              key={product.id}
              prodId={product.id}
              productName={product?.name}
              price={product?.price.unit_amount / 100}
            />
          ))}
        </div>
        {/* 
        {data && <p>{JSON.stringify(data)}</p>}
        {error && <p>{JSON.stringify(error)}</p>} */}
      </div>
      <EmpowerHerAccess />
      <ApplyForAccess />
    </NonProtectedLayout>
  );
};

export default Pricing;

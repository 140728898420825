import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import "./NonProtectedLayout.css";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginSuccess } from "../../store/authSlice";

const NonProtectedLayout = ({ children, showFooter = true }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    if (token && user) {
      dispatch(loginSuccess({ token, user: JSON.parse(user) }));
      navigate("/");
    }
  }, []);
  return (
    <div className="NonProtectedLayoutContainer">
      <Navbar />
      {children}
      {showFooter && <Footer />}
    </div>
  );
};

export default NonProtectedLayout;

import React from "react";
import "./OurAmbassadors.css";
import LazyImage from "../../LazyImage/LazyImage";

const ourAmbassadors = [
  { name: "Kathryn Cleary", src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Kathryn+Cleary.webp" },
  { name: "Lucy Namakula", src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Lucy+Namakula.webp" },
  { name: "Priya Kumari", src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Priya+Kumari.webp" },
];

const OurAmbassadors = () => {
  return (
    <div className="OurAmbassadorsSection">
      <div className="OurAmbassadorsContainer">
        <div className="OurAmbassadorsLeftContent">
          <div className="AmbassadorText">Meet our</div>
          <div className="AmbassadorMainHeading">AMBASSADORS</div>
        </div>
        <div className="OurAmbassadorsRightContent">
          <p>
            Get inspired by the incredible women who are already making a
            difference as Taara Ambassadors.
          </p>
        </div>
      </div>
      <div className="AllAmbassadors">
        {ourAmbassadors?.map((profile, idx) => (
          <div className="relative w-full mobile:w-[263px] h-[458px] mobile:h-[308px]">
            <LazyImage
              src={profile.src}
              alt={profile.name}
              className="w-full h-[458px] mobile:h-[308px] object-cover rounded-[20px]"
              bg="none"
            />

            <div class="absolute bottom-0 w-full h-full p-5 bg-gradient-to-b from-transparent via-black/40 to-black rounded-b-[20px]">
              <p className="absolute w-full text-center bottom-8 font-nohemi text-white text-2xl mobile:text-lg">
              {profile.name}
            </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurAmbassadors;

import React, { useEffect } from "react";
import Markdown from "../../components/Markdown/Markdown";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";

const markdownContent = `
## **Privacy Policy**

**Effective Date: 20.10.2024**

TAARA QUEST by apergo UG (hb) ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our mobile-optimized website (including any other media form, media channel, mobile website, or mobile application related or connected thereto). Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.

**1. INFORMATION WE COLLECT**

We may collect information about you in a variety of ways. The information we may collect on the Site includes:

- **Personal Data**: Personally identifiable information, such as your name and email address, that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site.
- **Derivative Data**: Information our servers automatically collect when you access the Site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the Site.
- **Financial Data**: Financial information, such as data related to your payment method (e.g., valid credit card number, card brand, expiration date) that we may collect when you purchase, order, return, exchange, or request information about our services from the Site.

**2. HOW WE USE YOUR INFORMATION**

Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:

- Create and manage your account.
- Deliver targeted advertising, coupons, newsletters, and other information regarding promotions and the Site to you.
- Email you regarding your account or order.
- Fulfill and manage purchases, orders, payments, and other transactions related to the Site.
- Increase the efficiency and operation of the Site.
- Monitor and analyze usage and trends to improve your experience with the Site.
- Notify you of updates to the Site.
- Offer new products, services, and/or recommendations to you.
- Perform other business activities as needed.
- Prevent fraudulent transactions, monitor against theft, and protect against criminal activity.
- Process payments and refunds.
- Request feedback and contact you about your use of the Site.
- Resolve disputes and troubleshoot problems.
- Respond to product and customer service requests.
- Solicit support for the Site.

**3. DISCLOSURE OF YOUR INFORMATION**

We may share information we have collected about you in certain situations. Your information may be disclosed as follows:

- **By Law or to Protect Rights**: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
- **Third-Party Service Providers**: We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.
- **Marketing Communications**: With your consent, or with an opportunity for you to withdraw consent, we may share your information with third parties for marketing purposes, as permitted by law.
- **Interactions with Other Users**: If you interact with other users of the Site, those users may see your name, profile photo, and descriptions of your activity, including sending invitations to other users, chatting with other users, liking posts, following blogs.
- **Online Postings**: When you post comments, contributions, or other content to the Site, your posts may be viewed by all users and may be publicly distributed outside the Site.
- **Business Partners**: We may share your information with our business partners to offer you certain products, services, or promotions.
- **Sale or Bankruptcy**: If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, your information would be an asset transferred or acquired by a third party. You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.

We are not responsible for the actions of third parties with whom you share personal or sensitive data, and we have no authority to manage or control third-party solicitations. If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.

**4. TRACKING TECHNOLOGIES**

- **Cookies and Web Beacons**: We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. When you access the Site, your personal information is not collected through the use of tracking technology. Most browsers are set to accept cookies by default. You can remove or reject cookies, but be aware that such action could affect the availability and functionality of the Site. You may not decline web beacons. However, they can be rendered ineffective by declining all cookies or by modifying your web browser’s settings to notify you each time a cookie is tendered, permitting you to accept or decline cookies on an individual basis.
- **Internet-Based Advertising**: Additionally, we may use third-party software to serve ads on the Site, implement email marketing campaigns, and manage other interactive marketing initiatives. This third-party software may use cookies or similar tracking technology to help manage and optimize your online experience with us. For more information about opting out of interest-based ads, visit the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.
- **Website Analytics**: We may also partner with selected third-party vendors, to allow tracking technologies on the Site through the use of first party cookies and third-party cookies, to, among other things, analyze and track users’ use of the Site, determine the popularity of certain content and better understand online activity. By accessing the Site, you consent to the collection and use of your information by these third-party vendors. You are encouraged to review their privacy policy and contact them directly for responses to your questions. We do not transfer personal information to these third-party vendors. However, if you do not want any information to be collected and used by tracking technologies, you can visit the third-party vendor or the Network Advertising Initiative Opt-Out Tool or Digital Advertising Alliance Opt-Out Tool.

You should be aware that getting a new computer, installing a new browser, upgrading an existing browser, or erasing or otherwise altering your browser’s cookies files may also clear certain opt-out cookies, plug-ins, or settings.

**5. THIRD-PARTY WEBSITES**

The Site may contain links to third-party websites and applications of interest, including advertisements and external services, that are not affiliated with us. Once you have used these links to leave the Site, any information you provide to these third parties is not covered by this Privacy Policy, and we cannot guarantee the safety and privacy of your information. Before visiting and providing any information to any third-party websites, you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, and should take those steps necessary to, in your discretion, protect the privacy of your information. We are not responsible for the content or privacy and security practices and policies of any third parties, including other sites, services or applications that may be linked to or from the Site.

**6. SECURITY OF YOUR INFORMATION**

We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. Therefore, we cannot guarantee complete security if you provide personal information.

**7. POLICY FOR CHILDREN**

We do not knowingly solicit information from or market to children under the age of 16. If you become aware of any data we have collected from children under age 16, please contact us using the contact information provided below.

**8. CONTROLS FOR DO-NOT-TRACK FEATURES**

Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.

**9. OPTIONS REGARDING YOUR INFORMATION**

You may at any time review or change the information in your account or terminate your account by:

- Logging in and selecting “delete account”
- Contacting us using the contact information provided below

Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.

If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:

- Noting your preferences at the time you register your account with the Site.
- Logging into your account settings and updating your preferences.
- Contacting us using the contact information provided below

If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.

**10. YOUR RIGHTS**

Under the General Data Protection Regulation, you are entitled to certain rights, including:

- Access, correct, update, or request deletion of your personal information.
- Object to processing of your personal information, ask us to restrict processing of your information, or request portability of your information.
- If we have collected and processed your personal information with your consent, then you can withdraw your consent at any time.

**11. JURISDICTION**

This Privacy Policy is governed by and construed in accordance with the laws of Germany, without giving effect to any principles of conflicts of law and will specifically not be governed by the United Nations conventions on contracts for the international sale of goods, if otherwise applicable. Any dispute arising from these terms shall be resolved exclusively in the state and federal courts of the City of Leipzig, Germany.

**12. INTERNATIONAL TRANSFER**

By sending us personal information, you agree and consent to the processing of your personal information in the United States, which may not offer an equivalent level of protection to that required in other countries (particularly the European Union), and to the processing of that information by us on servers located in the United States, as described in this Privacy Policy.

We have implemented safeguards designed to ensure that the personal information we process remains protected in accordance with this Privacy Policy, including when processed internationally or by our third-party service providers and partners.

**13. CONTACT US**

If you have questions or comments about this Privacy Policy, please contact us at:

**TAARA QUEST by apergo UG (hb)**  
Gohliser Str. 16  
04105 Leipzig  
Germany  
[help@taara.quest](mailto:help@taara.quest)
  `;

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <NonProtectedLayout>
      <div className="px-[198px] mobile:px-4 pb-4">
        <Markdown content={markdownContent} />
      </div>
    </NonProtectedLayout>
  );
};

export default PrivacyPolicy;
